const firebaseConfig = {
    apiKey: "AIzaSyAwNVAPWIHwplmEZsn1LuHm_XnDLxdCE3U",
    authDomain: "rent-service-535eb.firebaseapp.com",
    databaseURL: "https://rent-service-535eb-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "rent-service-535eb",
    storageBucket: "rent-service-535eb.appspot.com",
    messagingSenderId: "325976605533",
    appId: "1:325976605533:web:a0d695f383a856eef01eb4",
    measurementId: "G-FJF2YRX839"
};

export default firebaseConfig;
