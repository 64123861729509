import React from 'react';
import {Paper, Typography, Button, Grid} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';

function Step3({formData, setActiveStep, calculateTotalAmount, calculateCoupone, sendReservation}) {
    const formatDate = (date) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return date.toLocaleDateString('de-DE', options).split('.').join('.');
    };

    return (
        <Paper elevation={1} sx={{p: 2}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>

                    <Typography variant="h5" gutterBottom>
                        Übersicht
                    </Typography>
                    <Typography gutterBottom>
                        <h4>Reservieren für:</h4>
                        {formData.firstName} {formData.lastName} <br/>
                        {formData.email} <br/>
                        {formData.phone}<br/>
                        Datum: {formData.selectedDate ? formatDate(formData.selectedDate) : '-'}<br/><br/>
                        Abholung: Liebenau, 8041 Graz (Wir werden Sie bezüglich der Abholung des KÄRCHER Puzzi 10/1 Pro kontaktieren)<br/>
                    </Typography>
                    <Typography gutterBottom>
                        <h4>Service </h4>
                        <List>
                            <ListItem dense>
                                <CardMedia
                                    component="img"
                                    sx={{
                                        width: { xs: 80, sm: 151 },
                                        marginRight: { xs: 2, sm: 1 } // Add margin to separate the image from the text
                                    }}
                                    image="/images/of1.jpg"
                                    alt="KÄRCHER Puzzi 10/1"
                                />
                                <ListItemText
                                    primary="1x KÄRCHER Puzzi 10/1 Professional"
                                    sx={{
                                        marginLeft: { xs: 2, sm: 0 } // Add margin to separate the text from the image
                                    }}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end">€20,00</IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            {formData.equipment.indexOf('floor-brush') !== -1 ?
                                <ListItem
                                    secondaryAction={
                                        <IconButton edge="end">5,00 €</IconButton>
                                    }
                                >
                                    <CardMedia
                                        component="img"
                                        sx={{
                                            width: { xs: 60, sm: 151 },
                                            marginRight: { xs: 2, sm: 1 } // Add margin to separate the image from the text
                                        }}
                                        image="/images/of2.jpg"
                                        alt="KÄRCHER Puzzi 10/1 Flexible Bodendüse"
                                    />
                                    <ListItemText
                                        primary="1x Flexible Bodendüse"
                                        sx={{
                                            marginLeft: { xs: 2, sm: 0 } // Add margin to separate the text from the image
                                        }}
                                    />
                                </ListItem> : ''
                            }
                            {formData.equipment.indexOf('upholstery-brush') !== -1 ?
                                <ListItem
                                    secondaryAction={
                                        <IconButton edge="end">5,00 €</IconButton>
                                    }
                                >
                                    <CardMedia
                                        component="img"
                                        sx={{
                                            width: { xs: 60, sm: 151 },
                                            marginRight: { xs: 2, sm: 1 } // Add margin to separate the image from the text
                                        }}
                                        image="/images/of3.jpg"
                                        alt="KÄRCHER Puzzi 10/1 Polsterdüse"
                                    />
                                    <ListItemText
                                        primary="1x Polsterdüse"
                                        sx={{
                                            marginLeft: { xs: 2, sm: 0 } // Add margin to separate the text from the image
                                        }}
                                    />
                                </ListItem> : ''
                            }
                            {formData.tabsAmount > 0 ?
                                <ListItem
                                    secondaryAction={
                                        <IconButton edge="end">{(formData.tabsAmount * 2) + ',00 €'}</IconButton>
                                    }
                                >
                                    <CardMedia
                                        component="img"
                                        sx={{
                                            width: { xs: 60, sm: 151 },
                                            marginRight: { xs: 2, sm: 1 } // Add margin to separate the image from the text
                                        }}
                                        image="/images/of4.jpg"
                                        alt="KÄRCHER Puzzi 10/1 Polsterdüse"
                                    />
                                    <ListItemText
                                        primary="CarpetPro Reiniger Tabs RM 760"
                                        secondary={
                                            <span>
                                                2,00€ - 1Tab, Insgesamt: {formData.tabsAmount} Tabs
                                            </span>
                                        }
                                        sx={{
                                            marginLeft: { xs: 2, sm: 0 } // Add margin to separate the text from the image
                                        }}
                                    />
                                </ListItem> : ''
                            }

                            {formData.coupon ?
                                <ListItem
                                    secondaryAction={
                                        <IconButton edge="end">{calculateCoupone(0) + ',00 €'}</IconButton>
                                    }
                                >
                                    <CardMedia
                                        component="img"
                                        sx={{
                                            width: { xs: 60, sm: 151 },
                                            marginRight: { xs: 2, sm: 1 }
                                        }}
                                        image="/images/gf1.jpg"
                                        alt="KÄRCHER Puzzi 10/1 Polsterdüse"
                                    />
                                    <ListItemText
                                        primary="Gutschein"
                                        secondary={
                                            <span>
                                                Zubehör Gratis  im Wert von 10,-€
                                            </span>
                                        }
                                        sx={{
                                            marginLeft: { xs: 2, sm: 0 }
                                        }}
                                    />
                                </ListItem> : ''
                            }

                        </List>

                        {formData.coupon ? <Stack sx={{pb: 5}}>
                            <ListItem
                                secondaryAction={
                                    <IconButton edge="end">
                                        Gutschein: {calculateCoupone(0)},00 €
                                    </IconButton>
                                }
                            />
                        </Stack> : ''
                        }
                        <Stack sx={{pb: 5}}>
                            <ListItem
                                secondaryAction={
                                    <IconButton edge="end">
                                        <b>SUMME: {calculateTotalAmount()} </b>
                                    </IconButton>
                                }
                            />
                        </Stack>
                    </Typography>

                    <Grid item xs={12} textAlign="right">
                        <Stack spacing={2} direction="row" justifyContent="flex-end">
                            <Button variant="outlined" color="primary"
                                    onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}>
                                Zurück
                            </Button>
                            <Button
                                variant="contained"
                                style={{backgroundColor: 'green', color: 'white'}}
                                onClick={() => sendReservation()}
                            >
                                Anmelden
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default Step3;
