import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Paper, Typography, Stepper, Step, StepLabel} from '@mui/material';
import { Link } from 'react-router-dom';

import './DatePickerStyles.css';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import {useOrderLogic} from './useOrderLogic';

function Order() {
    const {
        formData,
        errors,
        activeStep,
        excludeDates,
        steps,
        handleChange,
        handleDateChange,
        handleToggle,
        calculateTotalAmount,
        handleSubmit,
        sendReservation,
        setActiveStep,
        calculateCoupone,
        twoMonthsFromNow,
        minDate,
        handleTosToggle,
        getDayClassName,
    } = useOrderLogic();

    const isMobile = useMediaQuery('(max-width:600px)'); // Adjust the breakpoint as needed


    return (
        <Paper sx={{ padding: '25px'}}>
            <Typography variant="h4" gutterBottom>
                Willkommen in unserer Vermietungs-App
            </Typography>
            <Typography variant="h6" gutterBottom>
                Hier können Sie das Kärcher Puzzi 10/1 Gerät zur professionellen Teppichreinigung mieten.
            </Typography>
            <Stepper
                activeStep={activeStep}
                alternativeLabel
                orientation={isMobile ? 'vertical' : 'horizontal'} // Set orientation based on screen size
                sx={{
                    mb: 5,
                    ...(isMobile && { alignItems: 'center' }), // Align step labels to center for mobile devices
                }}
            >
                {steps.map((label, index) => (
                    (isMobile && index === activeStep) || !isMobile ? ( // Conditionally render steps
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ) : null
                ))}
            </Stepper>
            {activeStep === 0 && (
                <Step1
                    formData={formData}
                    handleChange={handleChange}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    twoMonthsFromNow={twoMonthsFromNow}
                    minDate={minDate}
                    handleDateChange={handleDateChange}
                    setActiveStep={setActiveStep}
                    excludeDates={excludeDates}
                    handleTosToggle={handleTosToggle}
                    getDayClassName={getDayClassName}
                />
            )}
            {activeStep === 1 && (
                <Step2
                    formData={formData}
                    setActiveStep={setActiveStep}
                    handleChange={handleChange}
                    errors={errors}
                    handleToggle={handleToggle}
                />
            )}
            {activeStep === 2 && (
                <Step3
                    formData={formData}
                    setActiveStep={setActiveStep}
                    calculateTotalAmount={calculateTotalAmount}
                    calculateCoupone={calculateCoupone}
                    sendReservation={sendReservation}
                />
            )}
            {activeStep === 3 && (
                <Step4
                    formData={formData}
                    setActiveStep={setActiveStep}
                />
            )}
        </Paper>
    );
}

export default Order;
