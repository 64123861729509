import {useState, useEffect} from 'react';
import {initializeApp} from "firebase/app";
import {getDatabase, ref, push, onValue} from "firebase/database";
import Swal from 'sweetalert2'
import firebaseConfig from '../Firebase/firebaseConfig';
import axios from 'axios';

export function useOrderLogic() {
    const [formData, setFormData] = useState({
        coupon: false,
        firstName: '',
        lastName: '',
        address: '',
        postal: '',
        city: '',
        email: '',
        phone: '',
        comment: '',
        selectedDate: null,
        rawSelectedDate: '',
        equipment: [],
        tos: false
    });
    const [errors, setErrors] = useState({});
    const [activeStep, setActiveStep] = useState(0);
    const [reservationDates, setReservationDates] = useState([]);
    const [excludeDates, setExcludeDates] = useState([]);

    const steps = ['Kundendaten', 'Service', 'Reservierungsübersicht'];

    useEffect(() => {
        initializeApp(firebaseConfig);
        fetchReservationDates((dates) => {
            setReservationDates(dates);
        });
    }, []);

    const datePickerKey = excludeDates.toString();

    useEffect(() => {
        const formattedDates = reservationDates.map((dateString) => {
            return new Date(dateString);
        });
        const validDates = formattedDates.filter(date => !isNaN(date.getTime()));

        // Create a set to store unique dates
        const uniqueDates = new Set(validDates.map(date => date.toDateString()));

        // Convert back to Date objects
        const uniqueValidDates = Array.from(uniqueDates).map(dateString => new Date(dateString));

        setExcludeDates(uniqueValidDates);
    }, [reservationDates]);

    const fetchReservationDates = (callback) => {
        const database = getDatabase();
        const reservationsRef = ref(database, 'reservations');

        onValue(reservationsRef, (snapshot) => {
            const reservationData = snapshot.val();
            if (reservationData) {
                const dates = Object.values(reservationData).map(reservation => reservation.selectedDate);
                callback(dates);
            } else {
                callback([]);
            }
        });
    };

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
        if (e.target && e.target.name === 'couponValue') {
            validateCoupon(e.target.value);
        }
    };

    const validateCoupon = (value) => {
        if (value === 'ZUB-OFF') {
            setFormData(formData => ({
                ...formData,
                coupon: true,
                equipment: ['floor-brush', 'upholstery-brush']
            }));
        } else {
            setFormData({...formData, ['coupon']: false});
        }
    }

    const handleDateChange = (date) => {
        setFormData({...formData, selectedDate: date});
    };

    const handleToggle = (value) => () => {
        const currentIndex = formData.equipment.indexOf(value);
        const newChecked = [...formData.equipment];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setFormData({...formData, equipment: newChecked});
    };

    const calculateCoupone = (amount) => {
        if (formData.coupon) {
            if (formData.equipment.indexOf('floor-brush') !== -1) {
                amount -= 5;
            }
            if (formData.equipment.indexOf('upholstery-brush') !== -1) {
                amount -= 5;
            }
        }
        return amount;
    }

    const calculateTotalAmount = () => {
        let amount = 20;

        if (formData.equipment.indexOf('floor-brush') !== -1) {
            amount += 5;
        }

        if (formData.equipment.indexOf('upholstery-brush') !== -1) {
            amount += 5;
        }

        if (formData.tabsAmount > 0) {
            const totalTabs = formData.tabsAmount * 2;
            amount += totalTabs;
        }

        amount = calculateCoupone(amount);

        return amount + ',00 €';
    }

    const validateFields = (formData) => {
        const newErrors = {};

        if (formData.firstName.trim() === '') {
            newErrors.firstName = 'Vorname ist ist ein Pflichtfeld';
        }
        if (formData.lastName.trim() === '') {
            newErrors.lastName = 'Nachname ist ist ein Pflichtfeld';
        }
        if (formData.address.trim() === '') {
            newErrors.address = 'Adresse ist ist ein Pflichtfeld';
        }
        if (formData.postal.trim() === '') {
            newErrors.postal = 'PLZ ist ist ein Pflichtfeld';
        }
        if (formData.postal.length > 4) {
            newErrors.postal = 'Bitte geben Sie eine gültige Postleitzahl ein.';
        }
        if (formData.city === undefined || formData.city.trim() === '') {
            newErrors.city = 'Ort ist ist ein Pflichtfeld';
        }
        if (!formData.email.trim() || !isValidEmail(formData.email.trim())) {
            newErrors.email = 'Bitte geben Sie eine gültige E-Mail-Adresse ein';
        }
        if (formData.phone.trim() === '') {
            newErrors.phone = 'Telefonnummer ist ist ein Pflichtfeld';
        }
        if (formData.selectedDate === null) {
            newErrors.selectedDate = 'Bitte wählen Sie das Datum aus';
        }

        if (!formData.tos) {
            newErrors.tos = 'Bitte akzeptieren Sie die Nutzungsbedingungen.';
        }

        return newErrors;
    }

    function isValidEmail(email) {
        return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const newErrors = validateFields(formData);

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const sendReservation = async () => {
        const pleaseWaitToast = Swal.fire({
            title: 'Bitte warten...',
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
                Swal.showLoading();
            }
        });

        try {
            await axios.post('/api/v1/contactform', {formData});
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            pleaseWaitToast.close();
            Swal.fire({
                title: "Vielen Dank für Ihre Reservierung.",
                icon: "success",
                button: {
                    text: 'OK',
                    visible: true,
                    closeModal: true,
                }
            });
            console.log('Reservation data has been successfully saved!');
        } catch (error) {
            pleaseWaitToast.close();
            Swal.fire({
                title: 'Ooooops!!!',
                text: 'Es ist ein Fehler aufgetreten! Bitte versuchen Sie es erneut oder wenden Sie sich an den Kundenservice!',
                icon: "error",
                button: {
                    text: 'OK',
                    visible: true,
                    closeModal: true,
                }
            });
            console.log(error);
        }
    };

    const twoMonthsFromNow = new Date();
    twoMonthsFromNow.setMonth(twoMonthsFromNow.getMonth() + 2);

    const minDate = new Date();
    minDate.setDate(minDate.getDate() + 1);

    const getDayClassName = (date) => {
        const isDisabled = excludeDates.some(excludeDate => {
            return (
                date.getDate() === excludeDate.getDate() &&
                date.getMonth() === excludeDate.getMonth() &&
                date.getFullYear() === excludeDate.getFullYear()
            );
        });

        const isFree = !isDisabled;
        const isSelected = formData.selectedDate && date.getTime() === formData.selectedDate.getTime();
        const isPast = date < new Date();

        let className = 'custom-day';
        if (isFree && !isPast) {
            className += ' free-day';
        } else if (!isPast) {
            className += ' disabled-day';
        }
        if (isSelected) {
            className += ' selected-day';
        }
        return className;
    };

    const handleTosToggle = (e) => {
        const isChecked = e.target.checked;

        setFormData(prevFormData => ({
            ...prevFormData,
            tos: isChecked,
        }));
    }

    return {
        formData,
        errors,
        activeStep,
        reservationDates,
        excludeDates,
        steps,
        handleChange,
        handleDateChange,
        handleToggle,
        calculateTotalAmount,
        handleSubmit,
        sendReservation,
        setActiveStep,
        calculateCoupone,
        twoMonthsFromNow,
        minDate,
        handleTosToggle,
        getDayClassName,
        datePickerKey
    };
}
