import React, {useEffect} from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ReactGA from "react-ga4";
import 'react-datepicker/dist/react-datepicker.css';
import Landing from './components/Landing';
import TermsOfService from './components/TermsOfService';

function App() {

    useEffect(() => {
        ReactGA.initialize('G-FJF2YRX839');
        ReactGA.send("pageview_" + window.location.pathname + window.location.search);
    }, []);

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Landing/>}/>
                <Route path="/terms" element={<TermsOfService/>}/>
            </Routes>
        </Router>
    );
}

export default App;
