import React from 'react';
import {Button, Grid, TextField, FormControlLabel, Checkbox} from '@mui/material';
import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";
import de from 'date-fns/locale/de';
import MenuItem from "@mui/material/MenuItem";

function Step1({
                   formData,
                   handleChange,
                   errors,
                   handleSubmit,
                   twoMonthsFromNow,
                   minDate,
                   handleDateChange,
                   setActiveStep,
                   excludeDates,
                   handleTosToggle,
                   getDayClassName,
                   datePickerKey
               }) {
    registerLocale('de', de);
    setDefaultLocale('de');

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Vorname"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        error={!!errors.firstName}
                        helperText={errors.firstName}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Nachname"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        error={!!errors.lastName}
                        helperText={errors.lastName}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        label="Adresse"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        error={!!errors.address}
                        helperText={errors.address}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="PLZ"
                        name="postal"
                        value={formData.postal}
                        onChange={handleChange}
                        error={!!errors.postal}
                        helperText={errors.postal}
                        fullWidth
                        type="number"
                        inputProps={{
                            min: 0,
                            step: 1,
                            inputMode: 'numeric',
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        select
                        label="Ort"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        error={!!errors.city}
                        helperText={errors.city}
                        fullWidth
                    >
                        <MenuItem value="Graz">Graz</MenuItem>
                        <MenuItem value="Graz Umgebung">Graz Umgebung</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        error={!!errors.email}
                        helperText={errors.email}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Telefonnummer"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        error={!!errors.phone}
                        helperText={errors.phone}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <div className={`calendar-error-container ${errors.selectedDate ? 'error' : ''}`}>
                        <div className="calendar-wrapper">
                            <DatePicker
                                key={datePickerKey}
                                selected={formData.selectedDate}
                                onChange={date => {
                                    const adjustedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
                                    handleDateChange(adjustedDate);
                                }}
                                dateFormat="MM/dd/yyyy"
                                placeholderText="Datum auwählen"
                                inline
                                minDate={minDate}
                                maxDate={twoMonthsFromNow}
                                excludeDates={excludeDates}
                                calendarClassName="custom-calendar"
                                dayClassName={getDayClassName}
                                customInput={<input style={{textAlign: 'center'}}/>}
                                locale="de"
                                error={!!errors.selectedDate}
                                utcOffset={0}
                            />
                        </div>
                        {errors.selectedDate && (
                            <div
                                className="error-message MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
                                {errors.selectedDate}
                            </div>
                        )}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                edge="end"
                                onChange={handleTosToggle}
                                checked={formData.tos}
                            />
                        }
                        label={
                            <span style={{ width: '100%', display: 'block', paddingLeft: '8px' }}>
                                Ich akzeptiere die Nutzungsbedingungen <br/>
                                {errors.tos && (
                                    <div
                                        className="error-message MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
                                        {errors.tos}
                                    </div>
                                )}
                            </span>
                        }
                        error={!!errors.tos}
                        helperText={errors.tos}
                        labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12} style={{textAlign: 'right'}}>
                    <Button type="submit" variant="contained" color="primary">
                        Weiter
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

export default Step1;
