import React from 'react';
import {
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemText,
    Container,
    Paper
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Header from "../Header";
import Footer from "../Footer";

function TermsOfService() {
    return (<>
            <Container maxWidth={false} sx={{background: '#c4ebff'}}>
                <Container>
                    <Header/>
                    <Paper sx={{ padding: '25px'}}>
                        <Typography variant="h5" align="center" gutterBottom>
                            Allgemeine Geschäftsbedingungen
                        </Typography>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography variant="subtitle1">1. Reservierung und Bestätigung</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    <ListItem>
                                        <ListItemText
                                            primary="Die Reservierung einer Reinigungsmaschine verpflichtet den Kunden zur Zahlung der vereinbarten Mietgebühr."/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary="Die Reservierungsbestätigung wird dem Kunden per E-Mail oder SMS zugesandt."/>
                                    </ListItem>
                                </List>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography variant="subtitle1">2. Gebühr und Kaution</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    <ListItem>
                                        <ListItemText
                                            primary="Die Mietgebühr für die Reinigungsmaschine ist im Voraus zu zahlen."/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary="Der Kunde ist verpflichtet, auch eine Kaution zu zahlen, die nach Rückgabe der Maschine in gutem Zustand erstattet wird."/>
                                    </ListItem>
                                </List>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography variant="subtitle1">3. Verwendung der Maschine</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    <ListItem>
                                        <ListItemText
                                            primary="Die Reinigungsmaschine darf nur von autorisierten Personen verwendet werden."/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary="Der Kunde ist für den ordnungsgemäßen Betrieb und die Wartung der Maschine während der Vermietung verantwortlich."/>
                                    </ListItem>
                                </List>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography variant="subtitle1">4. Haftung</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    <ListItem>
                                        <ListItemText
                                            primary="Der Kunde haftet für etwaige Schäden oder Verluste der Maschine während der Vermietung."/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary="Schäden, die durch unsachgemäße Handhabung verursacht wurden, werden zusätzlich in Rechnung gestellt."/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary="Der Vermieter behält sich das Recht vor, den Ausweis des Mieters zu verlangen, der während der Übergabe als Nachweis für die Übernahme des Geräts fotografiert wird."/>
                                    </ListItem>
                                </List>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography variant="subtitle1">5. Rückgabe der Maschine</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    <ListItem>
                                        <ListItemText
                                            primary="Die Reinigungsmaschine muss zum vereinbarten Zeitpunkt und in einwandfreiem Zustand zurückgegeben werden."/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary="Eine verspätete Rückgabe der Maschine kann zusätzliche Kosten verursachen."/>
                                    </ListItem>
                                </List>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography variant="subtitle1">6. Stornierung der Reservierung</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    <ListItem>
                                        <ListItemText
                                            primary="Die Reservierung der Maschine kann bis zu einem bestimmten Zeitpunkt ohne zusätzliche Kosten storniert werden."/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary="Eine Stornierung der Reservierung nach Ablauf der Frist kann zum Verlust eines Teils oder des gesamten Mietpreises führen."/>
                                    </ListItem>
                                </List>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography variant="subtitle1">7. Änderungen und Ergänzungen</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    <ListItem>
                                        <ListItemText
                                            primary="Alle Änderungen oder Ergänzungen der Reservierung müssen im Voraus vereinbart und bestätigt werden."/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary="Diese Allgemeinen Geschäftsbedingungen dienen als Richtlinie und können an die spezifischen Anforderungen und Bedingungen Ihres Unternehmens angepasst werden."/>
                                    </ListItem>
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    </Paper>
                </Container>
                <Footer/>
            </Container>
        </>
    );
}

export default TermsOfService;
