import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles} from "@material-ui/core/styles";
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: 250,
    },
    appBarSpacer: theme.mixins.toolbar,
}));

const Header = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleToggleDrawer = () => {
        setOpen(!open);
    };
    return (
        <AppBar position="sticky">
            <Toolbar>
                <Typography variant="h6">
                    <Link href="./" color="inherit" style={{display: "flex", alignItems: "center"}}>
                        {/*<img src="../images/gf1.jpg" alt="logo" style={{width: "50px", marginRight: "8px"}}/>*/}
                        Clean Graz
                    </Link>
                </Typography>
                <div style={{marginLeft: "auto"}}>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleToggleDrawer}>
                        <MenuIcon/>
                    </IconButton>
                </div>
            </Toolbar>
            <Drawer anchor="right" open={open} onClose={handleToggleDrawer}>
                <div
                    className={classes.drawer}
                    role="presentation"
                    onClick={handleToggleDrawer}
                    onKeyDown={handleToggleDrawer}
                >
                    <div className={classes.appBarSpacer}/>
                    <List>
                        <Link href="./">
                            <ListItem button>
                                <ListItemText primary="Reservierung"/>
                            </ListItem>
                        </Link>
                        <Link href="/terms">
                            <ListItem button>
                                <ListItemText primary="AGB"/>
                            </ListItem>
                        </Link>
                        <Link href="mailto:info@cleangraz.com" target="_blank">
                            <ListItem button>
                                <ListItemText primary="Kontakt"/>
                            </ListItem>
                        </Link>
                    </List>
                </div>
            </Drawer>

        </AppBar>
    );
};

export default Header;
