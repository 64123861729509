import React from 'react';
import Header from "../Header";
import Footer from "../Footer";
import Order from "../Order";
import {Container} from '@mui/material';

const Landing = () => {
    return (
        <Container maxWidth={false} sx={{background: '#c4ebff'}}>
            <Container>
                <Header/>
                <Order/>
            </Container>
            <Footer/>
        </Container>
    );
};

export default Landing;
