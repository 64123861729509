import React from 'react';
import {Paper, Button, Grid, TextField} from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

import Checkbox from '@mui/material/Checkbox';

function Step2({formData, setActiveStep, handleChange, errors, handleToggle}) {

    return (
        <Paper elevation={1} sx={{p: 2}}>
            <Grid container spacing={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>

                        <Divider sx={{pt: 2}}>
                            <Chip label="Gerät" size="small"/>
                        </Divider>

                        <List
                            sx={{
                                width: '100%',
                                maxWidth: 600,
                                bgcolor: 'background.paper',
                                margin: 'auto',
                                textAlign: 'center'
                            }}
                        >
                            <ListItem>
                                <ListItemIcon>
                                    <CardMedia
                                        component="img"
                                        sx={{width: { xs: 60, sm: 140 }}}
                                        image="/images/of1.jpg"
                                        alt="KÄRCHER Puzzi 10/1"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    id="switch-list-label-device"
                                    primary={<span>KÄRCHER Puzzi 10/1 Professional</span>}
                                    secondary={<span>
                                        Im Preis inbegriffen:<br/>
                                        - Puzzi 10/1 Gerät<br/>
                                        - Sprüh-/Saugpistole <br/>
                                        - Sprüh-/Saugschlauch - 2,5 m<br/>
                                        - Entnehmbarer 2-in-1-Behälter
                                        <br/>
                                    </span>}
                                />
                                <Chip label="20€" variant="outlined"/>
                            </ListItem>
                        </List>

                    </Grid>
                </Grid>

                <Grid item xs={12}>

                    <Divider>
                        <Chip label="Zubehör" size="small"/>
                    </Divider>

                    <List
                        sx={{
                            width: '100%',
                            maxWidth: 600,
                            bgcolor: 'background.paper',
                            margin: 'auto',
                            textAlign: 'center'
                        }}
                    >
                        <ListItem
                            secondaryAction={
                                <Checkbox
                                    edge="end"
                                    onChange={handleToggle('floor-brush')}
                                    checked={formData.equipment.indexOf('floor-brush') !== -1}
                                    inputProps={{'aria-labelledby': 'floor-brush'}}
                                />
                            }>
                            <ListItemIcon>
                                <CardMedia
                                    component="img"
                                    sx={{width: { xs: 60, sm: 140 }}}
                                    image="/images/of2.jpg"
                                    alt="KÄRCHER Puzzi 10/1"
                                />
                            </ListItemIcon>
                            <ListItemText
                                id="switch-list-label-floor-brush"
                                primary={<span>Flexible Bodendüse <Chip label="5€" variant="outlined"/></span>}
                                secondary={
                                    <span>
                                        Optimal zum Reinigen von Teppichen<br/>
                                        Im Preis inbegriffen:<br/>
                                        - D-Handgriff für Sprüh-/Saugrohr <br/>
                                    </span>
                                }
                            />

                        </ListItem>

                        <ListItem
                            secondaryAction={
                                <Checkbox
                                    edge="end"
                                    onChange={handleToggle('upholstery-brush')}
                                    checked={formData.equipment.indexOf('upholstery-brush') !== -1}
                                    inputProps={{'aria-labelledby': 'upholstery-brush'}}
                                />
                            }>
                            <ListItemIcon>
                                <CardMedia
                                    component="img"
                                    sx={{width: { xs: 60, sm: 140 }}}
                                    image="/images/of3.jpg"
                                    alt="KÄRCHER Puzzi 10/1"
                                />
                            </ListItemIcon>
                            <ListItemText
                                id="switch-list-label-upholstery-brush"
                                primary={<span>Polsterdüse <Chip label="5€" variant="outlined"/></span>}
                                secondary={
                                    <span>
                                        Optimal zum Reinigen von Polstermöbeln/KFZ<br/>
                                        Im Preis inbegriffen:<br/>
                                        - D-Handgriff für Sprüh-/Saugrohr <br/>
                                    </span>
                                }
                            />
                        </ListItem>
                    </List>
                    <Divider>
                        <Chip label="Reinigungsmittel" size="small"/>
                    </Divider>
                    <List
                        sx={{
                            width: '100%',
                            maxWidth: 600,
                            bgcolor: 'background.paper',
                            margin: 'auto',
                            textAlign: 'center'
                        }}
                    >
                        <ListItem>
                            <ListItemIcon>
                                <CardMedia
                                    component="img"
                                    sx={{width: { xs: 60, sm: 140 }}}
                                    image="/images/of4.jpg"
                                    alt="KÄRCHER Puzzi 10/1"
                                />
                            </ListItemIcon>
                            <ListItemText
                                id="switch-list-label-wifi"
                                primary={<span>CarpetPro Reiniger Tabs RM 760<br/>
                                    <Chip label="2€ - 1Tab" variant="outlined"/></span>}
                                secondary={<><br/>
                                    <TextField
                                        select
                                        label="Tabs"
                                        name="tabsAmount"
                                        value={formData.tabsAmount}
                                        onChange={handleChange}
                                        error={!!errors.tabsAmount}
                                        helperText={errors.tabsAmount}
                                        fullWidth
                                    >
                                        {[...Array(10).keys()].map((num) => (
                                            <MenuItem key={num} value={num}>
                                                {num}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </>}
                            />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <Divider>
                            <Chip label="Anmerkung" size="small"/>
                        </Divider>
                        <List
                            sx={{
                                width: '100%',
                                maxWidth: 600,
                                bgcolor: 'background.paper',
                                margin: 'auto',
                                textAlign: 'center'
                            }}
                        >
                            <ListItem>
                                <ListItemText
                                    primary={<>
                                        <TextField
                                            label="Anmerkung"
                                            name="comment"
                                            onChange={handleChange}
                                            value={formData.comment}
                                            fullWidth
                                        />
                                    </>}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <Divider>
                            <Chip label="Gutschein" size="small"/>
                        </Divider>
                        <List
                            sx={{
                                width: '100%',
                                maxWidth: 600,
                                bgcolor: 'background.paper',
                                margin: 'auto',
                                textAlign: 'center'
                            }}
                        >
                            <ListItem>
                                <ListItemText
                                    id="switch-list-label-couponCode"
                                    primary={formData.coupon === false && <>
                                        <TextField
                                            label="Gutscheincode"
                                            name="couponValue"
                                            value={formData.couponValue}
                                            onChange={handleChange}
                                            error={!!errors.couponValue}
                                            helperText={errors.couponValue}
                                            fullWidth
                                        />
                                    </>}
                                    secondary={formData.coupon && <span style={{ textAlign: 'center' }}>
                                        <h3 className="green-notification">Der Gutschein ist gültig!<br/>
                                            Sie erhalten kostenlos das Zubehör im Wert von 10€!</h3></span>}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>

                <Grid item xs={12} textAlign="right">
                    <Stack spacing={2} direction="row" justifyContent="flex-end">
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
                        >
                            Zurück
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
                        >
                            Weiter
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default Step2;
