import React from 'react';
import {Paper, Typography, Button, Grid} from '@mui/material';

function Step4({formData, setActiveStep}) {
    return (
        <Paper elevation={1} sx={{p: 2}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom align="center"> {/* Dodajte align="center" */}
                        Vielen Dank für Ihre Reservierung!<br/><br/>
                        Ihr Termin wurde erfolgreich in unserer Datenbank gespeichert!<br/>
                        Wir werden Sie bezüglich der Abholung/Lieferung des KÄRCHER Puzzi 10/1 Pro kontaktieren!<br/><br/>
                        Ihr Clean Graz Team!
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default Step4;
