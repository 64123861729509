import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const Footer = () => {
    return (
        <Container maxWidth={false} sx={{background: '#58a8d0'}}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container justify="center">
                        <Grid item>
                            {/*<img src="../images/gf1.jpg" alt="logo" style={{ width: "50px" }} />*/}
                            <Typography variant="h5" style={{marginLeft: "8px"}}>
                                Clean Graz
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify="center">
                        <Grid item>
                            <Link href="/" style={{ marginRight: "16px", color: "black" }}>
                                Reservierung
                            </Link>
                            <Link href="/terms" style={{marginRight: "16px", color: "black"}}>
                                AGB
                            </Link>
                            <Link href="mailto:info@cleangraz.com" style={{marginRight: "16px", color: "black"}}>
                                Kontakt
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify="center">
                        <Grid item>
                            <Typography variant="body1" style={{marginTop: "16px"}}>
                                &copy; 2024 | cleanGraz.com | Alle Rechte vorbehalten.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Footer;
